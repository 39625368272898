import React, { useState, Fragment } from "react"
import Piece from "../components/Piece"
import tataki from "../images/tataki.jpg"
import gyoza from "../images/gyoza.jpg"
import edamame from "../images/edamame.jpg"
import kake from "../images/kake.jpg"
import wakame from "../images/wakame.jpg"
import jap from "../images/EntréeJap.png"

import {
  CategoryContainer,
  HalfTextContainer,
  CategoryName,
  ImageContainer,
  Jap,
} from "./shared/carte"

const Starters = () => {
  const [selectedImage, setSelectedImage] = useState(tataki)

  const handlePictureChange = image => {
    setSelectedImage(image)
  }
  return (
    <Fragment>
      <CategoryContainer>
        <ImageContainer>
          <img
            className="bg-image"
            rel="preload"
            src={selectedImage}
            alt="Les entrées de Hara Kiri Ramen Restaurant"
          ></img>
          <Jap>
            <img
              rel="preload"
              src={jap}
              alt="Les entrées de Hara Kiri Ramen Restaurant écrit en japonais"
            ></img>
          </Jap>
        </ImageContainer>
        <HalfTextContainer className="starters">
          <CategoryName>Entrées</CategoryName>
          <Piece
            name="tataki"
            onClick={handlePictureChange.bind(this, tataki)}
            dishName={"TATAKI DE BOEUF"}
            price={"9,00 €"}
            text={
              "Tataki mariné avec une recette maison, servi avec une sauce wasabi yuzu"
            }
            selected={selectedImage.includes("tataki")}
          />
          <Piece
            name="edamame"
            onClick={handlePictureChange.bind(this, edamame)}
            dishName={"EDAMAME"}
            price={"5,00 €"}
            text={"Haricots japonais avec fleur de sel & de Shichimi"}
            selected={selectedImage.includes("edamame")}
          />
          <Piece
            name="gyoza"
            onClick={handlePictureChange.bind(this, gyoza)}
            dishName={"GYOZA"}
            price={"6,00 €"}
            text={"Gyoza de poulet et de légumes"}
            selected={selectedImage.includes("gyoza")}
          />
          <Piece
            name="kake"
            onClick={handlePictureChange.bind(this, kake)}
            dishName={"KAKE GHOAN"}
            price={"3,50 €"}
            text={"Bol de riz blanc avec un jaune d'oeuf"}
            selected={selectedImage.includes("kake")}
          />
          <Piece
            name="wakame"
            onClick={handlePictureChange.bind(this, wakame)}
            dishName={"SALADE WAKAME"}
            price={"4,50 €"}
            text={"Petite salade d’algues wakame et gingembre"}
            selected={selectedImage.includes("wakame")}
          />
        </HalfTextContainer>
      </CategoryContainer>
    </Fragment>
  )
}

export default Starters
