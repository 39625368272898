import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Starters from "../components/Starters"

const IndexPage = () => (
  <Layout headerColor={"black"}>
    <SEO
      title="Les entrées du restaurant hara kiri ramen"
      description="Découvrez les differentes entrées proposées par le restaurant hara kiri"
    />
    <Starters />
  </Layout>
)

export default IndexPage
